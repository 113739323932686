import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import EventCard from './EventCard';

const Heading = styled.div`
  color: white;
  margin: 24px 0 48px 0;
  text-align: center;
  color: var(--white);
  font-size: 48px;
  transform: rotate(-3deg);
  h1 {
    text-shadow: 5px 5px rgba(0, 0, 0, 0.6);
  }
  @media (max-width: 769px) {
    font-size: 32px;
  }
`;

const List = styled.div`
  max-width: var(--page-width);
  width: 100%;
`;

export default function Events() {
  const eventsList = useStaticQuery(graphql`
    query EventsQuery {
      events: allSanityEvent {
        nodes {
          id
          eventTitle
          eventEndDate
          eventStartDate
          eventArtists {
            artistName
          }
          location {
            venueName
          }
          slug {
            current
          }
        }
      }
    }
  `);
  const events = eventsList.events.nodes;
  return (
    <List>
      <Heading>
        <h1>Latest Events</h1>
      </Heading>
      {events.map((event) => {
        const title = event.eventTitle
          ? event.eventTitle
          : event.eventArtists.map((artist) => artist.artistName).join(', ');
        return (
          <EventCard
            key={event.id}
            title={title}
            date={event.eventStartDate}
            venue={event.location[0].venueName}
            slug={event.slug.current}
          />
        );
      })}
    </List>
  );
}
