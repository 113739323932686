import React from 'react';
import styled from 'styled-components';

const Heading = styled.div`
  color: white;
  text-align: center;
  color: var(--white);
  font-size: 24px;
  transform: rotate(-3deg);
  display: flex;
  align-items: center;
  h1 {
    text-shadow: 5px 5px rgba(0, 0, 0, 0.6);
  }
  @media (min-width: 500px) {
    font-size: 48px;
  }
`;

export default function ComingSoon() {
  return (
    <Heading>
      <h1>Coming Soon!</h1>
    </Heading>
  );
}
