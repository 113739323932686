import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const EventStyles = styled.div`
  background: white;
  padding: 1.5rem;
  margin: 0 auto 32px auto;
  border: solid 2px var(--black);
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.6);
  width: 100%;
`;

const EventTitle = styled.div`
  border-bottom: dotted 2px;
  padding-bottom: 10px;
`;

const EventDetails = styled.div`
  display: flex;
  border-bottom: dotted 2px;
  margin-bottom: 16px;
  .event-date,
  & > div {
    padding: 8px;
  }
`;

export default function EventCard({ title, date, venue, slug }) {
  return (
    <EventStyles>
      <EventTitle>
        <h2>{title}</h2>
      </EventTitle>
      <EventDetails>
        <div className="event-date">
          <p>{date}</p>
        </div>
        <div>{venue}</div>
      </EventDetails>
      <div>
        <Link to={`/events/${slug}`} className="buy-tickets">
          Osta lippuja
        </Link>
      </div>
    </EventStyles>
  );
}
