import React from 'react';
import Events from '../components/Events';
import SEO from '../components/SEO';
import ComingSoon from '../components/ComingSoon';

const devMode = process.env.GATSBY_DEV_MODE;

export default function HomePage() {
  return (
    <>
      <SEO title="Home" />
      {devMode === 'false' ? <Events /> : <ComingSoon />}
    </>
  );
}
